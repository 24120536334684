.contato__interna__desc{
	text-align: justify;
	margin-bottom: 30px;
}
.segura__form__btn{
	padding-top: 20px;
	display: flex;
	justify-content: center;

	.btn__conteudo{
		background-color: transparent;

		&:hover{
			background-color: #f48144;

			&:focus{
				outline: none;
			}
		}
	}
}
