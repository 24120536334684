.main__menu{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
}
.contato__box{
	display: flex;
	align-items: center;
	margin: 0px 10px;
}
.main__contato{
	display: flex;
}
.contato__icone{
	width: 54px;
	height: 54px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f48144;
	color: #fff;
	border-radius: 50%;
	margin-right: 10px;
	font-size: 18px;
	line-height: 18px;
}
.contato__titulo{
	font-family: 'montserratbold';
	font-size: 13px;
	line-height: 17px;
}
.contato__mail,
.contato__tels{
	display: flex;
	flex-wrap: wrap;
	font-size: 13px;
	line-height: 17px;
}
.main__sociais{
	display: flex;

	a{
		display: flex;
		position: relative;
		width: 35px;
		height: 35px;
		margin-right: 5px;
		align-items: center;
		justify-content: center;
		color: #00a859;
		transition: all 0.3s linear;

		&:hover{
			color: #f48144;
			text-decoration: none;

			&:before{
				opacity: 1;
			}
		}

		&:before{
			width: 100%;
			height: 100%;
			content: '';
			position: absolute;
			top: 0px;
			left: 0px;
			background-image: url('../images/detalhe__sociais.png');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			opacity: 0;
			transition: all 0.3s linear;
		}

		&:last-child{
			margin-right: 0px;
		}
	}
}
@include media-breakpoint-up (lg) {
	.topo{
		position: sticky;
		top: -92px;
		z-index: 10;
		box-shadow: 0px 5px 10px rgba(#000, 0.2);
	}
	.topo__main{
		background-color: #fff;
	}
	.main__cima{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px 0px;
		border-bottom: 1px solid rgba(#555555, 0.5);
	}
	.main__baixo{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.main__menu{
		display: flex;
	}
	.topo__mbl{
		display: none;
	}
	.menu__item{
		display: flex;
		margin-right: 30px;

		&.menu__item--active{
			font-family: 'montserratblack';
			color: #f5864b;
		}

		&:last-child{
			margin-right: 0px;
		}
	}
	.menu__link{
		display: flex;
		padding: 20px 0px;
		font-size: 13px;
		line-height: 13px;
		color: #f5864b;
		transition: all 0.3s linear;

		&:hover{
			font-family: 'montserratblack';
			color: #f5864b;
			text-decoration: none;
		}
	}
	.main__baixo{
		.main__logo{
			display: none;
		}
	}
}
@include media-breakpoint-down (md) {
	.topo{
		position: sticky;
		top: 0px;
		z-index: 10;
		box-shadow: 0px 5px 10px rgba(#000, 0.2);
	}
	.topo__main{
		position: fixed;
		top: 0px;
		left: -250px;
		width: 250px;
		height: 100%;
		overflow-y: auto;
		transition: all 0.4s linear;
		z-index: 1000;
		background-color: #fff;

		.container{
			padding: 0px;
			display: flex;
			flex-direction: column;
		}

		&.topo__main--shown{
			left: 0px;
		}
	}
	.main__contato{
		flex-direction: column;
		align-items: center;
	}
	.contato__box{
		flex-direction: column;
		align-items: center;
		text-align: center;
	}
	.main__logo{
		padding: 10px;
		display: block;
		margin-bottom: 10px;
	}
	.contato__tels,
	.contato__mail{
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 20px;

		.barrinha{
			display: none;
		}
	}
	.main__cima{
		order: 2;

		.main__logo{
			display: none;
		}
	}
	.contato__icone{
		margin-right: 0px;
		margin-bottom: 10px;
	}
	.main__baixo{
		order: 1;
	}
	.menu__link{
		display: block;
		padding: 10px;
		font-family: 'montserratmedium';
		color: #f5864b;
		transition: all 0.3s linear;

		&:hover{
			background-color: #f5864b;
			color: #fff;
			text-decoration: none;
		}
	}
	.menu__item{
		&.menu__item--active{
			.menu__link{
				background-color: #f5864b;
				color: #fff;
			}
		}
	}
	.main__menu{
		margin-bottom: 10px;
	}
	.main__sociais{
		justify-content: center;
		margin-bottom: 20px;

		a{
			margin: 0px 8px;

			&:before{
				opacity: 1;
			}
		}
	}
	.bg__menu{
		position: fixed;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background-color: rgba(#000, 0.6);
		z-index: 999;
		animation: fadeIn 0.4s linear;

		&.hide{
			animation: fadeOut 0.4s linear;
		}
	}
	.topo__mbl{
		padding: 10px 0px;
		background-color: #fff;

		.container{
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
	.mbl__botao{
		border: none;
		background-color: transparent;
		padding: 0px;
		font-size: 30px;
		line-height: 30px;

		&:focus{
			outline: none;
		}
	}
}
@include media-breakpoint-down (sm) {
	.mbl__logo{
		display: block;
		max-width: 200px;
		margin-right: 10px;
	}
}
@include media-breakpoint-down (sm) {
	.mbl__logo{
		display: block;
		max-width: 170px;
		margin-right: 10px;
	}
}
