.titulo__localizacao{
	font-family: 'montserratbold';
	font-size: 23px;
	line-height: 27px;
	color: #00a859;
	padding-bottom: 10px;
	margin-bottom: 20px;
	border-bottom: 2px solid #f48144;
}
@include media-breakpoint-down (md) {
	.localizacao__mapa{
		margin-bottom: 30px;
	}
}
.interna__procedimento{
	overflow: hidden;
}
