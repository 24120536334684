.banner__conteudo{
	max-width: 1920px;
	width: 100%;
	overflow: hidden;
	margin-right: auto;
	margin-left: auto;
	position: relative;
}
.banner__inner{
	width: 160%;
	max-width: 1920px;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}
.banner__indicators{
	justify-content: flex-start;
	margin-bottom: 30px;

	li{
		width: 20px;
		height: 20px;
		opacity: 1;
		background-color: #fff;
		border-radius: 50%;
		position: relative;
		margin: 0px 7px;

		&.active{
			&:after{
				opacity: 1;
			}
		}

		&:after{
			width: 34px;
			height: 34px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-image: url('../images/detalhe__sociais.png');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			content: '';
			opacity: 0;
			transition: all 0.3s linear;
		}
	}
}
.quem__somos{
	padding: 70px 0px 0px 0px;
	overflow: hidden;
}
.titulo__secao{
	font-family: 'montserratbold';
	font-size: 14px;
	line-height: 18px;
	color: #f48144;
	margin-bottom: 5px;
	padding-left: 15px;
	position: relative;

	&:before{
		width: 5px;
		height: 5px;
		content: '';
		position: absolute;
		top: 50%;
		left: 0px;
		transform: translateY(-50%);
		border-radius: 50%;
		background-color: #f48144;
	}
}
.sub__secao{
	font-family: 'montserratbold';
	font-size: 23px;
	line-height: 27px;
	color: #00a859;
}
.quem__somos__header{
	margin-bottom: 15px;
}
.quem__somos__desc{
	text-align: justify;
	margin-bottom: 20px;
}
.quem__somos__img{
	display: inline-block;
	position: relative;
	max-width: 100%;

	&:after{
		width: 107px;
		height: 96px;
		content: '';
		position: absolute;
		top: -40px;
		right: 210px;
		background-image: url('../images/quem__somos__detalhe.png');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
	&:before{
		width: 20px;
		height: 20px;
		content: '';
		position: absolute;
		left: -30px;
		top: 50px;
		border-radius: 50%;
		background-color: #f48144;
	}
}
.quem__somos__tabs{
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: none;
	flex-wrap: wrap;
	margin-bottom: 20px;
	width: 100%;
	border-radius: 45px;
	background-color: #f48144;
	padding-bottom: 1px;
	overflow: hidden;

	.nav-link{
		width: 140px;
		height: 45px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 45px;
		background-color: #f48144;
		border: none;
		margin: 0px;
		transition: all 0.3s linear;
		font-family: 'montserratbold';
		font-size: 14px;
		line-height: 14px;
		color: #fff;

		&.active{
			border: none;
			background-color: #f48144;
			box-shadow: 0px 0px 10px 8px rgba(#000, 0.3);
			color: #fff;
		}
	}
}
.quem__somos__conteudo{
	text-align: justify;
}
.especialidade__card{
	display: block;
	margin-bottom: 30px;

	&:hover{
		text-decoration: none;

		.segura__img__especialidade{
			&:before{
				opacity: 1;
			}
			&:after{
				opacity: 1;
			}
		}

		.especialidade__img{

			&:after,
			&:before{
				opacity: 1;
			}
			img{
				opacity: 0.4;
			}
		}
		.especialidade__titulo{
			color: #f48144;
		}
		.especialidade__txt{
			&:before{
				opacity: 1;
			}
			&:after{
				opacity: 1;
			}
		}
	}
}
.especialidade__img{
	mask-image: url('../images/mascara.png');
	mask-repeat: no-repeat;
	mask-size: 100% auto;
	mask-position: center;
	width: 256px;
	max-width: 100%;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 20px;
	background-color: #f48144;
	position: relative;

	&:after{
		width: 38px;
		height: 20px;
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-30%, -100%);
		background-image: url('../images/olho__img.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		z-index: 3;
		opacity: 0;
		transition: all 0.3s linear;
	}

}
.segura__img__especialidade{
	position: relative;
	padding-top: 20px;
	max-width: 256px;
	margin-right: auto;
	margin-left: auto;
	background-image: url('../images/bg__img__especialidade.png');
	background-position: right;
	background-size: 227px 247px;
	background-repeat: no-repeat;


	&:after{
		width: 42px;
		height: 54px;
		content: '';
		position: absolute;
		left: 0px;
		bottom: 30px;
		background-image: url('../images/detalhe__especialidades.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		transition: all 0.3s linear;
		opacity: 0;
	}
	&:before{
		width: 30px;
		height: 30px;
		content: '';
		position: absolute;
		bottom: 50px;
		right: -15px;
		background-color: rgba(#f48144, 0.5);
		border-radius: 50%;
		border: 1px solid #f48144;
		z-index: 5;
		opacity: 0;
		transition: all 0.3s linear;
	}
}
.especialidade__titulo{
	font-family: 'montserratbold';
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	margin-bottom: 10px;
	transition: all 0.3s linear;
}
.especialidade__item__desc{
	text-align: center;
}
.especialidades__header{
	margin-bottom: 30px;
}
.especialidade__txt{
	&:before{
		content: '';
		position: absolute;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: #f48144;
		top: 30px;
		left: 50%;
		transform: translateX(-110px);
		opacity: 0;
		transition: all 0.3s linear;
	}
	&:after{
		content: '';
		position: absolute;
		width: 18px;
		height: 18px;
		border: 1px solid #f48144;
		top: 15px;
		left: 50%;
		transform: translateX(40px);
		opacity: 0;
		transition: all 0.3s linear;
		border-radius: 50%;
	}
}
.btn__conteudo{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 200px;
	height: 50px;
	border-radius: 50px;
	border: 2px solid #f48144;
	color: #f48144;
	font-family: 'montserratbold';
	font-size: 14px;
	line-height: 14px;
	transition: all 0.3s linear;

	&:hover{
		text-decoration: none;
		background-color: #f48144;
		color: #fff;
	}
}
.especialidades__btn{
	padding-top: 20px;
	display: flex;
	justify-content: center;
}
.especialidades{
	padding: 100px 0px 60px 0px;
	background-image: url('../images/bg__especialidade.png');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
.vert .carousel-item-next.carousel-item-left,
.vert .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.vert .carousel-item-next,
.vert .active.carousel-item-right {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100% 0);
}

.vert .carousel-item-prev,
.vert .active.carousel-item-left {
	-webkit-transform: translate3d(0,-100%, 0);
    transform: translate3d(0,-100%, 0);
}
.exame__figura{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 127px;
	min-width: 127px;
	height: 135px;
	position: relative;
	margin-right: 15px;

	&:before{
		content: '';
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background-image: url('../images/exame__detalhe.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		filter: grayscale(100%);
		transition: all 0.3s linear;
	}
}
.exame__card{
	display: flex;
	align-items: center;
	margin-bottom: 25px;

	&:hover{
		text-decoration: none;

		.exame__figura{
			&:before{
				filter: grayscale(0%);
			}

			.exame__img{
				background-color: #f48144;
			}
		}
		.exame__titulo{
			color: #f48144;
		}
	}

	&:focus{
		outline: none;
		text-decoration: none;
	}
}
.exame__img{
	width: 80px;
	height: 70px;
	mask-size: auto;
	mask-repeat: no-repeat;
	mask-position: center;
	mask-image: var(--imagem);
	background-color: #909090;
	transition: all 0.3s linear;
}
.exame__desc{
	margin-bottom: 35px;
	text-align: justify;
}
.exame__titulo{
	font-family: 'montserratextrabold';
	font-size: 18px;
	line-height: 22px;
	color: #555555;
	margin-bottom: 10px;
	transition: all 0.3s linear;
}
.exame__item__desc{
	text-align: justify;
}
.exames__carousel{
	display: flex;
	align-items: center;
}
.exames__controles{
	margin-right: 20px;
	a{
		display: block;
		margin: 20px 0px;

		&:hover{
			i{
				background-color: #f48144;
			}
		}
		i{
			background-color: #00ab59;
			transition: all 0.3s linear;
		}
	}
}
.exame__header{
	margin-bottom: 15px;
}
.exames{
	padding: 30px 0px 30px 0px;
}
@include media-breakpoint-up (lg) {
	.especialidades__header{
		display: flex;
		justify-content: space-between;
		align-items: center;

		hgroup{
			max-width: 440px;
			width: 100%;
		}
		.especialidades__desc{
			max-width: 460px;
			width: 100%;
		}
	}
	.exames{
		max-width: 1920px;
		width: 100%;
		overflow: hidden;
		margin-right: auto;
		margin-left: auto;
		position: relative;
	}
	.exames__inner{
		width: 160%;
		max-width: 1920px;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
	}
	.exames__col{
		width: 55%;

		&:first-child{
			display: flex;
			justify-content: flex-end;
		}

		&:last-child{
			width: 45%;
		}
	}
	.exame__conteudo{
		width: 100%;
		max-width: 670px;
		padding-right: 15px;
	}
}
@include media-breakpoint-only (lg) {
	.quem__somos__tabs{
		.nav-item{
			.nav-link{
				width: 110px;
			}
		}
	}
	.exame__conteudo{
		width: 100%;
		max-width: 540px;
		padding-right: 15px;
	}

}
@include media-breakpoint-down (md) {
	.quem__somos{
		.col-lg-7{
			order: 2;
		}
		.col-lg-5{
			order: 1;
		}
	}
	.quem__somos__img{
		&:after{
			display: none;
		}
	}
	.rodape__conteudo{
		.col-lg-6{
			&:first-child{
				order: 2;
			}
			&:last-child{
				order: 1;
			}
		}
	}
	.rodape__creditos{
		flex-direction: column;
		align-items: center;
		text-align: center;

		.creditos{
			margin-bottom: 10px;
		}
	}
}
@include media-breakpoint-down (xs) {
	.exame__card{
		flex-direction: column;
		align-items: center;
		text-align: center;
		display: flex;
	}
	.exame__figura{
		margin-right: 0px;
		margin-bottom: 15px;
	}
	.quem__somos__tabs{
		align-items: center;
		flex-direction: column;
		.nav-link{
			margin: 15px;
		}
	}
	.rodape__form{
		margin-bottom: 20px;
	}
	.rodape__contato{
		.col-lg-3{
			margin-bottom: 10px;
		}
	}
}
.segura__btn__conteudo__1,
.segura__btn__conteudo__2{
	background-color: transparent;
	border: none;
	padding: 0px;
	position: relative;
	border-radius: 50%;

	&:focus{
		outline: none;
	}

	&:after{
		width: 100%;
		height: 100%;
		content: '';
		position: absolute;
		left: 0px;
		top: 0px;
		border-radius: 50%;
		z-index: 5;
	}
	.btn__conteudo{
		position: relative;
		z-index: 3;
	}
	&:hover{
		.btn__conteudo{
			text-decoration: none !important;
			background-color: #f48144 !important;
			color: #fff !important;
		}
	}
}
