@font-face {
    font-family: 'montserratblack';
    src: url('#{$fonts}/montserrat-black/montserrat-black-webfont.eot');
    src: url('#{$fonts}/montserrat-black/montserrat-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.svg#montserratblack') format('svg');
    font-weight: normal;
    font-style: normal;

}
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 8, 2019 */



@font-face {
    font-family: 'montserratbold';
    src: url('../fonts/montserrat-bold/montserrat-bold-webfont.eot');
    src: url('../fonts/montserrat-bold/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-bold/montserrat-bold-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-bold/montserrat-bold-webfont.woff') format('woff'),
         url('../fonts/montserrat-bold/montserrat-bold-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-bold/montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: normal;
    font-style: normal;

}
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 14, 2019 */



@font-face {
    font-family: 'montserratextrabold';
    src: url('../fonts/montserrat-extrabold/montserrat-extrabold-webfont.eot');
    src: url('../fonts/montserrat-extrabold/montserrat-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-extrabold/montserrat-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-extrabold/montserrat-extrabold-webfont.woff') format('woff'),
         url('../fonts/montserrat-extrabold/montserrat-extrabold-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-extrabold/montserrat-extrabold-webfont.svg#montserratextrabold') format('svg');
    font-weight: normal;
    font-style: normal;

}
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 14, 2019 */



@font-face {
    font-family: 'montserratmedium';
    src: url('../fonts/montserrat-medium/montserrat-medium-webfont.eot');
    src: url('../fonts/montserrat-medium/montserrat-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-medium/montserrat-medium-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-medium/montserrat-medium-webfont.woff') format('woff'),
         url('../fonts/montserrat-medium/montserrat-medium-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-medium/montserrat-medium-webfont.svg#montserratmedium') format('svg');
    font-weight: normal;
    font-style: normal;

}
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 8, 2019 */



@font-face {
    font-family: 'montserratregular';
    src: url('../fonts/montserrat-regular/montserrat-regular-webfont.eot');
    src: url('../fonts/montserrat-regular/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-regular/montserrat-regular-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-regular/montserrat-regular-webfont.woff') format('woff'),
         url('../fonts/montserrat-regular/montserrat-regular-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-regular/montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'robotoregular';
    font-display: optional;
    src: url('#{$fonts}/roboto_regular/roboto-regular-webfont.eot');
    src: url('#{$fonts}/roboto_regular/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.woff') format('woff'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
