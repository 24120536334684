.paginacao__custom{
	display: flex;
	justify-content: flex-end;
}
.paginacao__item{
	display: flex;
	position: relative;
	width: 35px;
	height: 35px;
	margin-right: 5px;
	align-items: center;
	justify-content: center;
	color: #00a859;
	transition: all 0.3s linear;
	font-family: 'montserratbold';

	&:hover{
		color: #f48144;
		text-decoration: none;

		&:before{
			opacity: 1;
		}
	}

	&:before{
		width: 100%;
		height: 100%;
		content: '';
		position: absolute;
		top: 0px;
		left: 0px;
		background-image: url('../images/detalhe__sociais.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		opacity: 0;
		transition: all 0.3s linear;
	}

	&:last-child{
		margin-right: 0px;
	}

	&.paginacao__item--active{
		color: #f48144;

		&:before{
			opacity: 1;
		}
	}
}
.especialidade__carousel{
	position: relative;

	.carousel__controls{
		display: flex;
		justify-content: center;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 30px;

		a{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 54px;
			height: 54px;
			border-radius: 50%;
			background-color: #f48144;
			color: #fff;
			font-size: 24px;
			line-height: 24px;
			margin: 0px 8px;
			transition: all 0.3s linear;

			&:hover{
				text-decoration: none;
				box-shadow: 0px 0px 0px 7px rgba(#f48144, 0.5);
			}
		}
	}
}
.titulo__especialidade__detalhe{
	font-family: 'montserratbold';
	font-size: 23px;
	line-height: 27px;
	color: #00a859;
	margin-bottom: 16px;
}
.desc__especialidade__desc{
	text-align: justify;
}

// PAGINA NOVA
.interna__especialidade__nova{
	.owl-carousel{
		.owl-nav{
			padding-top: 20px;

			&:not(.disabled){
				display: flex;
				justify-content: center;
				column-gap: 10px;
			}

			.owl-prev,
			.owl-next{
				width: 40px;
				height: 40px;
				min-width: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 2px solid #00a859;
				color: #00a859;
				font-size: 18px;
				transition: all 0.3s linear;
				line-height: 18px;
				background-color: transparent;
				border-radius: 50%;

				&:focus{
					border-color: #00a859;
				}

				&:hover{
					background-color: #00a859;
					color: #fff;
				}
			}
		}
	}
	.row{
		align-items: center;

	}
	.segura__btn__form{
		background-color: #fff;
		display: flex;
		align-items: flex-end;
		box-shadow: 0px 0px 5px rgba(#000, 0.3);
		border-radius: 25px;
		padding-right: 6px;
		padding-bottom: 6px;

		.btn__conteudo{
			.icon__mask{
				background-color: currentColor;
			}
			color: #f48144;
			border: 2px solid #f48144;
			width: 50px;
			min-width: 50px;
			height: 50px;
			background-color: transparent;
			transition: all 0.3s linear;

			&:hover{
				background-color: #f48144;
				color: #fff;
			}
		}

		textarea,
		input{
			box-shadow: none;
			resize: none;
		}
	}
	.secao__especialidade__servicos{
		margin-top: 50px;
		padding-top: 60px;
		border-top: 5px solid #e4e4e4;
		padding-bottom: 60px;
	}
}
.grid__servicos__espec{
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 30px;
	row-gap: 30px;

	@include media-breakpoint-down (lg) {
		grid-template-columns: repeat(2, 1fr);
	}
	@include media-breakpoint-down (sm) {
		grid-template-columns: 1fr;
	}
}
.dep__espec{
	padding: 45px 0px;
	background-color: #eefaf3;
}
.titulo__dep{
	font-family: montserratbold;
	font-size: 25px;
	line-height: 28px;
	color: #00a859;
	margin-bottom: 25px;
	text-align: center;
}
.depoimento__card{
	background-color: #fff;
	padding: 45px 30px 72px 30px;
	position: relative;
	border-radius: 25px;
	box-shadow: 0px 0px 5px rgba(#000, 0.3);

	.dep__nome{
		color: #00a859;
		font-size: 16px;
		line-height: 20px;

		span{
			font-family: montserratbold;
		}
	}

	.dep__desc{
		text-align: justify;
		font-family: montserratmedium;
		font-size: 14px;
		line-height: 18px;
		color: #555555;
		margin-bottom: 30px;
	}

	.dep__icone{
		position: absolute;
		left: 30px;
		max-width: 90px;
		bottom: -45px;

		img{
			border-radius: 50%;
		}

		&:after{
			width: 31px;
			height: 31px;
			position: absolute;
			right: 0px;
			top: 0px;
			background-image: url(../images/aspas.png);
			background-repeat: no-repeat;
			background-size: auto;
			background-position: center;
			content: '';
		}
	}
}
.dep__espec{
	.owl-item{
		padding-bottom: 54px;
	}
}

.saude__nossa{
	padding: 95px 0px;

	.titulo__dep{
		margin-bottom: 40px;
	}

	@include media-breakpoint-down (sm) {
		padding: 45px 0px;
	}
}

.saude__card{
	.saude__icone{
		background-image: url('../images/saude-moldura.png');
		background-repeat: no-repeat;
		background-size: auto;
		background-position: center;
		min-width: 127px;
		width: 100%;
		min-height: 135px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;

		img{
			max-width: 60px;
		}
	}
	.saude__titulo{
		font-family: montserratbold;
		font-size: 18px;
		line-height: 22px;
		text-align: center;
		margin-bottom: 15px;
		color: #00a859;
		max-width: 280px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
	.saude__desc{
		text-align: center;
		color: #555555;
		font-family: montserratmedium;
		font-size: 14px;
		line-height: 18px;
	}
}
.form__espec{
	background-color: #ffefe6;
	padding-top: 60px;
	padding-bottom: 50px;

	.titulo__dep{
		margin-bottom: 30px;
	}

	.form__contato{
		max-width: 650px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
}
