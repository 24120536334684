.rodape{
	background-image: url('../images/bg__rodape.png');
	background-size: 1920px 100%;
	background-position: center top;
	background-repeat: no-repeat;
	padding-top: 50px;

	&.rodape__especial{
		background-color: #ffefe6;
	}

	&.rodape__interna{
		.rodape__conteudo{
			display: none;
		}
	}
}
.mapa__rodape{
	mask-image: url('../images/mapa__detalhe.png');
	mask-repeat: no-repeat;
	mask-size: 100%;
	mask-position: center;
}
.rodape__header{
	margin-bottom: 20px;
}
.rodape__input{
	width: 100%;
	display: block;
	height: auto;
	padding: 13px 25px;
	border-radius: 35px;
	border: none;
	box-shadow: 0px 0px 5px rgba(#000, 0.3);

	&:focus{
		outline: none;
	}
}
.rodape__data{
	position: relative;

	&:after{
		content: '';
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 15px;
		width: 30px;
		height: 30px;
		background-image: url('../images/calendario.png');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}

	.rodape__input{
		padding: 13px 45px 13px 25px;
	}
}
.rodape__horario{
	position: relative;

	&:after{
		content: '';
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 15px;
		width: 30px;
		height: 30px;
		background-image: url('../images/relogio.png');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}

	.rodape__input{
		padding: 13px 45px 13px 25px;
	}
}
.rodape__conteudo{
	margin-bottom: 50px;
}
.segura__contato__btn{
	display: flex;
	justify-content: flex-end;
	padding-top: 10px;

	.btn__conteudo{
		background-color: transparent;

		&:hover{
			background-color: #f48144;
		}

		&:focus{
			outline: none;
		}
	}
}
.rodape__titulo{
	font-family: 'montserratbold';
	font-size: 13px;
	line-height: 17px;
	color: #f48144;
	margin-bottom: 10px;
}
.rodape__contato{
	padding-bottom: 20px;
	border-bottom: 1px solid rgba(#555555, 0.5);
}
.rodape__tels{
	display: flex;
	flex-direction: column;
}
.rodape__mails{
	display: flex;
	flex-direction: column;
}
.sociais__titulo{
	font-family: 'montserratbold';
	font-size: 13px;
	line-height: 17px;
	color: #f48144;
}
.segura__sociais__rodape{
	display: flex;
	align-items: center;
	height: 100%;

	.sociais__titulo{
		margin-right: 20px;
	}

	.sociais__rodape{
		display: flex;

		a{
			display: flex;
			position: relative;
			width: 35px;
			height: 35px;
			margin-right: 5px;
			align-items: center;
			justify-content: center;
			color: #00a859;
			transition: all 0.3s linear;

			&:hover{
				color: #f48144;
				text-decoration: none;

				&:before{
					opacity: 1;
				}
			}

			&:before{
				width: 100%;
				height: 100%;
				content: '';
				position: absolute;
				top: 0px;
				left: 0px;
				background-image: url('../images/detalhe__sociais.png');
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
				opacity: 0;
				transition: all 0.3s linear;
			}

			&:last-child{
				margin-right: 0px;
			}
		}
	}
}
.rodape__creditos{
	display: flex;
	justify-content: space-between;
	padding: 15px 0px;
	font-size: 10px;
	line-height: 14px;
	align-items: center;
}
.gv8__box{
	display: flex;
	align-items: center;

	.icon{
		margin-left: 5 px;
	}
}
.btn__whatsapp{
	width: 50px;
	height: 50px;
	position: fixed;
	bottom: 25px;
	right: 25px;
	z-index: 900;
	opacity: 1;
	border-radius: 50%;
	transition: all 0.3s linear;
	box-shadow: 0px 0px 5px rgba(#000, 0.3);

	&:hover{
		box-shadow: 0px 0px 15px rgba(#000, 0.6);
	}
}
