.interna__bread{
	padding: 13px 0px 10px 0px;
	background-color: #f1faf5;
	text-align: right;
	margin-bottom: 50px;
}
.interna__conteudo{
	padding-bottom: 50px;
}
.interna__quem__somos{
	.quem__somos__tabs{
		position: relative;
		.nav-item{
			width: 30%;
		}
		.nav-link{
			width: 100%;
		}
	}
}
